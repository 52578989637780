import { useAction, useSelector } from "@preact-hooks/unistore";
import { useEffect, useState, useRef } from "preact/hooks";
import actions from "store/actions";
import { Video } from "models/video";
import { ShopifyProduct } from "models/user";
import { objectsDifferences } from "util/objectsDifferences";
import {
  videoBySlug,
  videoByShortcode,
  searchVideos,
  videoToken,
} from "services/video";
import { getShopifyEquipment } from "services/shopify";
import { shouldSearch } from "util/SearchUtil";
import { SearchVideoOptions } from "models/search";
import { appRoute } from "util/routerUtil";
import useUser from "hooks/userHook";
import { PlaybackToken } from "models/videoDetail";

interface SearchVideosHook {
  searchResults: { data: Video[]; total: number } | null;
  elements: Video[] | null;
  isLoading: boolean;
}

export function useSearchVideos(
  opts?: SearchVideoOptions | null,
  forceReset = false
): SearchVideosHook {
  const [elements, setElements] = useState<Video[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentSearchParams, setCurrentSearchParams] =
    useState<SearchVideoOptions | null>(null);
  const setSearchOptsAction = useAction(actions.setSearchOpts);
  const setSearchResultsAction = useAction(actions.setSearchResults);
  const { searchResults, searchOpts } = useSelector("searchResults,searchOpts");

  useEffect(() => {
    if (forceReset) {
      if (!opts) {
        setElements([]);
        setSearchResultsAction(null);
        setIsLoading(false);
      }
    }
  }, [forceReset, opts]);

  useEffect(() => {
    if (
      opts &&
      Object.keys(opts).length &&
      shouldSearch(opts) &&
      (!searchResults ||
        JSON.stringify(opts) !== JSON.stringify(currentSearchParams))
    ) {
      setCurrentSearchParams(opts);
      setIsLoading(true);

      (async () => {
        try {
          const diff = objectsDifferences(opts, searchOpts);
          setSearchOptsAction(opts);

          if (!Object.keys(diff).length) {
            setIsLoading(false);
            return;
          }

          if (!diff?.from) {
            setElements([]);
            setSearchResultsAction(null);
            if (opts && opts.from) {
              opts.from = 0;
            }
          }

          // The searchVideos service now handles duplicate request prevention
          const { data = [], total = 0 } = await searchVideos(opts);

          setSearchResultsAction({ data, total });

          if (opts.q !== searchOpts.q) {
            setElements(data || []);
          } else if (data?.length) {
            setElements((prevState) => [...prevState, ...data]);
          } else if (diff?.from === undefined) {
            setElements([]);
          }
        } catch (error) {
          console.error("Search error:", error);
        } finally {
          setIsLoading(false);
        }
      })();
    } else if (!opts) {
      if (elements.length > 0) {
        setElements([]);
      }
      if (searchResults) {
        setSearchResultsAction(null);
      }
      setIsLoading(false);
    }
  }, [opts && JSON.stringify(opts)]);

  return { searchResults, elements, isLoading };
}

interface SingleRoutineHook {
  currentVideo: Video | null;
  setCurrentVideo: (vid: Video) => void;
}

let fetchingRoutine = false;

export function useSingleRoutine(
  slug: string | null = null
): SingleRoutineHook {
  const setCurrentVideoAction = useAction(actions.setCurrentVideo);
  const { currentVideo } = useSelector("currentVideo");
  useEffect(() => {
    if (slug && currentVideo?.slug !== slug && !fetchingRoutine) {
      (async () => {
        try {
          fetchingRoutine = true;
          const { data: video } = await videoBySlug(slug);
          if (!video && slug?.length == 8) {
            const videoInfo = await videoByShortcode(slug);
            if (videoInfo?.data) {
              setCurrentVideoAction(videoInfo.data);
              appRoute(`/routine/${videoInfo.data.slug}`, true);
              fetchingRoutine = false;
            }
          } else {
            setCurrentVideoAction(video);
            fetchingRoutine = false;
          }
        } catch (error) {}
      })();
    }
  }, [slug]);

  const setCurrentVideo = (video: Video): void => {
    setCurrentVideoAction(video);
  };

  return { currentVideo, setCurrentVideo };
}

interface VideoTokenHook {
  currentVideoToken: PlaybackToken | null;
  setCurrentVideoToken(token: string | null): void;
  currentPoseToken: PlaybackToken | null;
  setCurrentPoseToken(token: string | null): void;
}

interface RoutineEquipmentHook {
  routineEquipment: ShopifyProduct[] | null;
}

interface PlayingVideoHook {
  playingVideo: boolean | null;
  setPlayingVideo(token: boolean | null): void;
}

export function useCurrentVideoToken(
  playbackId: string | string[] | null,
  videoId?: number | null,
  type = "routine"
): VideoTokenHook {
  const setCurrentVideoTokenAction = useAction(actions.setCurrentVideoToken);
  const { currentVideoToken } = useSelector("currentVideoToken");
  const { currentPoseToken } = useSelector("currentPoseToken");
  const setCurrentPoseTokenAction = useAction(actions.setCurrentPoseToken);
  const routine = type === "routine";
  const pose = type === "pose";

  const { errorRedirect } = useUser();
  useEffect(() => {
    if (videoId && playbackId) {
      (async () => {
        try {
          const data = await videoToken(playbackId);
          routine && setCurrentVideoTokenAction(data);
          pose && setCurrentPoseTokenAction(data);
        } catch (error: any) {
          errorRedirect(error);
        }
      })();
    } else {
      routine && setCurrentVideoTokenAction(null);
      pose && setCurrentPoseTokenAction(null);
    }
  }, [videoId]);
  return {
    currentVideoToken,
    currentPoseToken,
    setCurrentPoseToken: setCurrentPoseTokenAction,
    setCurrentVideoToken: setCurrentVideoTokenAction,
  };
}

export function useRoutineEquipment(): RoutineEquipmentHook {
  const setCurrentRoutineEquipment = useAction(actions.setRoutineEquipment);
  const { routineEquipment } = useSelector("routineEquipment");
  useEffect(() => {
    (async () => {
      try {
        const products = await getShopifyEquipment();
        setCurrentRoutineEquipment(products);
      } catch (error) {
        console.error("ERROR", error);
        setCurrentRoutineEquipment([]);
      }
    })();
  }, []);
  return { routineEquipment };
}

export const usePlayingVideo = (): PlayingVideoHook => {
  const setPlayingVideo = useAction(actions.setPlayingVideo);
  const { playingVideo } = useSelector("playingVideo");

  return { playingVideo, setPlayingVideo };
};

interface lastFullVideoHook {
  lastFullVideo: string;
  setLastFullVideoAction(lastFullVideo?: string | number | undefined): void;
}

export const useLastFullVideoHook = (): lastFullVideoHook => {
  const { lastFullVideo } = useSelector("lastFullVideo");
  const setLastFullVideoAction = useAction(actions.setLastFullVideo);
  return { lastFullVideo, setLastFullVideoAction };
};
