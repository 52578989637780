import { h, FunctionalComponent } from "preact";
import { forwardRef } from "preact/compat";
import { useState } from "preact/hooks";
import { styled } from "linaria/react";

import {
  WORLD_COUNTRIES,
  GENDERS,
  INDUSTRIES,
  COMPANY_SIZES,
  ROLES,
} from "util/constants";
import Colors from "style/colors";
import Text from "components/shared/Text";

interface InputProps {
  id: string;
  label: string;
  type:
    | "text"
    | "number"
    | "email"
    | "password"
    | "date"
    | "gender"
    | "country"
    | "industry"
    | "role"
    | "companySize";
  value: string;
  error?: string;
  autocomplete?: string;
  onChange?: (e: Event) => void;
  onPaste?: (e: Event) => void;
  onBlur?: (e: Event) => void;
  disabled?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  ref?: any;
  placeholder?: string;
  isSafari?: boolean;
  className?: string;
}

const Container = styled.div<{
  invalid: boolean;
  isSelect: boolean;
  hasValue: boolean;
}>`
  display: flex;
  flex-flow: column-reverse;
  height: ${(props) => (props.isSelect ? "4.5rem" : "3.5rem")};
  position: relative;

  > select {
    cursor: pointer;
  }

  > input,
  > select {
    width: 100%;
    height: 56px;
    background-color: inherit;
    color: ${Colors.txtDark};
    font-size: 18px;
    font-family: "Gilroy";
    font-weight: normal;
    border: none;
    padding: 16px 12px;
    border-radius: 8px !important;

    &[type="date"] {
      padding: 1rem 0;
      cursor: pointer;
      z-index: 1;
    }
    border: 1px solid ${Colors.bgDark3};
    transition: border-bottom 0.1s linear;
    outline: none;

    ::placeholder {
      transition: all 0.2s ease-in-out;
      transform: translate(0, 0);
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      caret-color: ${Colors.txtLight};
      -webkit-text-fill-color: ${Colors.bgDark0};
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
      box-shadow: 0 0 0 1000px ${Colors.bgLight2} inset;
      font-size: 1rem;
      transition: background-color 5000s ease-in-out 0s;
    }

    :-webkit-autofill:focus,
    :focus {
      ::placeholder {
        transform: translate(0, -1.5rem);
        color: transparent;
      }
    }

    border-bottom: ${({ invalid }) =>
      invalid ? `1px solid ${Colors.error}` : `1px solid ${Colors.bgDark3}`};

    /* Calendar */
    ::-webkit-calendar-picker-indicator {
      cursor: pointer;
      background-image: url("https://optimize.romwod.com/image/upload/q_auto/v1654171787/app/calendar-icon.png");
      background-repeat: no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
      background-size: 24px 24px;
    }

    ::placeholder {
      transition: inherit;
      transform: none;
      font-family: "Gilroy";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: ${Colors.txtGrey3};
    }
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("https://optimize.romwod.com/image/upload/q_auto/v1654171304/app/select-arrow.png");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    background-size: 13px 7px;
  }

  > select {
    > option {
      background: ${Colors.bgDark1};
      span {
        color: ${Colors.txtLight};
      }
    }
  }

  :-webkit-autofill + label,
  input:focus + label,
  select:focus + label,
  input:not(:placeholder-shown) + label,
  select:not(:placeholder-shown) + label,
  select[aria-invalid="false"] + label {
    display: inherit;
    transform: translate(0, 0);
    visibility: visible;
    font-size: 0.875rem;
  }

  > label {
    visibility: ${({ hasValue }) => (hasValue ? "visible" : "hidden")};
    display: inherit;
    font-family: "Roboto";
    font-weight: normal;
    font-size: 1rem;
    color: ${({ invalid }) =>
      invalid ? `${Colors.error}` : `${Colors.bgDark0}`} !important;
    line-height: 140%;
    z-index: 1;
  }

  > label {
    visibility: ${({ hasValue }) => (hasValue ? "visible" : "hidden")};
    display: inherit;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    font-size: 12px !important;
    color: ${({ invalid }) =>
      invalid ? `${Colors.error}` : `${Colors.bgDark0}`} !important;
    line-height: 16px;
    z-index: 1;
    position: absolute;
    top: -8px;
    left: 8px;
    background: ${Colors.bgLight2};
    padding: 0px 4px;
  }

  > span {
    position: absolute;
    right: 0;
    bottom: 1.5rem;
    cursor: pointer;
    color: ${Colors.txtLight};
  }

  .error {
    margin-bottom: ${({ invalid }) => (invalid ? `12px` : 0)};
    min-height: 20px;
    display: block;

    span {
      font-size: 0.75rem;
      font-weight: 400;
      color: ${Colors.error};
    }
  }

  input {
    border: ${({ invalid }) =>
      invalid ? `1px solid ${Colors.error}` : `1px solid ${Colors.bgDark0}`};
  }
`;

const CalendarIcon = styled.img`
  position: absolute;
  top: 16px;
  right: 15px;
  z-index: 0;
`;

const Input: FunctionalComponent<InputProps> = forwardRef(
  (props: InputProps, ref: any) => {
    const {
      id,
      type,
      label,
      disabled,
      value,
      error,
      onChange,
      onPaste,
      placeholder,
      isSafari,
      className = "",
    } = props;
    const [innerType, setInnerType] = useState(type);
    const lists = {
      country: WORLD_COUNTRIES,
      gender: GENDERS,
      industry: INDUSTRIES,
      companySize: COMPANY_SIZES,
      role: ROLES,
    };
    const iconInnerType =
      innerType === "password"
        ? "v1654606578/app/eyeOff.png"
        : "v1654606585/app/eyeOn.png";

    const toggleShowPassword = () => {
      if (innerType === "password") {
        setInnerType("text");
      } else setInnerType("password");
    };

    const getDateBoundaries = () => {
      if (type !== "date") return {};

      const formatter = new Intl.DateTimeFormat("fr-CA", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      const max = formatter.format(new Date());
      const min = "1920-01-01";
      return { min, max };
    };

    const isSelect = [
      "gender",
      "country",
      "industry",
      "companySize",
      "role",
    ].includes(type);

    return (
      <Container
        invalid={Boolean(error)}
        isSelect={isSelect}
        hasValue={Boolean(value)}
        className={className}
      >
        <div class="error">
          <Text size="S">{error || "‎‎‏‏‎ "}</Text>
        </div>
        {isSelect ? (
          <select
            {...props}
            value={value}
            type={innerType}
            id={id}
            name={id}
            onChange={onChange}
            placeholder={label}
            disabled={disabled}
            defaultValue={value}
          >
            <option hidden disabled value="">
              <Text color={Colors.txtGrey1} size="S">
                {placeholder || "Select"}
              </Text>
            </option>
            {lists[type].map(({ name, value }, index) => (
              <option key={`${index}-${value}`} value={value}>
                {name}
              </option>
            ))}
          </select>
        ) : (
          <input
            {...props}
            value={value}
            type={innerType}
            id={id}
            name={id}
            onPaste={onPaste}
            onChange={onChange}
            placeholder={placeholder || label}
            disabled={disabled}
            autoCorrect={innerType ? "off" : "on"}
            ref={ref}
            {...getDateBoundaries()}
          />
        )}
        <label htmlFor={id}>{label}</label>
        {type === "password" && (
          <span onClick={toggleShowPassword} className={"password-eye-icon"}>
            <img
              src={`https://optimize.romwod.com/image/upload/q_auto/${iconInnerType}`}
              width={24}
              height={24}
            />
          </span>
        )}
        {type === "date" && isSafari && (
          <CalendarIcon
            src="https://optimize.romwod.com/image/upload/q_auto/v1654171787/app/calendar-icon.png"
            alt="calendar icon"
            width={24}
            height={24}
          />
        )}
      </Container>
    );
  }
);

export default Input;
