import { Tier } from "models/subscription";
import dayjs from "dayjs";

export interface ListCSV {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  memberSince: string;
}

export const checkDecimal = (number = 0) => {
  const decimalPart = number % 1;

  const hasDecimal = decimalPart !== 0;

  return hasDecimal ? Number(number?.toFixed(1)) : number;
};

export const getAngleRotation = (percent = 0, restPercent = 0) => {
  let angleRotation = 0;

  if (percent <= 1) {
    angleRotation = 35.25;
  } else if (percent <= 2) {
    angleRotation = 35.6;
  } else if (percent <= 3) {
    angleRotation = 35.9;
  } else if (percent <= 4) {
    angleRotation = 35.9;
  } else if (percent <= 40) {
    angleRotation = 36.1;
  } else if (percent <= 50) {
    angleRotation = 36.1;
  } else if (percent <= 70) {
    angleRotation = 35.9;
  } else if (percent <= 80) {
    angleRotation = 35.6;
  } else if (percent <= 85) {
    angleRotation = 35.3;
  } else if (percent <= 90) {
    angleRotation = 34.5;
  } else if (percent < 100) {
    angleRotation = 34;
  } else {
    angleRotation = 36.2;
  }

  return (restPercent / 10) * angleRotation;
};

export const getCircleLength = (percent = 0, restPercent = 0) => {
  let circleLength = 0;

  if (percent <= 1) {
    circleLength = restPercent - 8;
  } else if (percent <= 2) {
    circleLength = restPercent - 7;
  } else if (percent <= 3) {
    circleLength = restPercent - 6;
  } else if (percent <= 4) {
    circleLength = restPercent - 6;
  } else if (percent === 0) {
    circleLength = 0;
  } else if (percent >= 50) {
    circleLength = restPercent - 5;
  } else {
    circleLength = restPercent - 5;
  }

  return circleLength;
};

export const getScaleGraph = (maxContentViewed = 100, numberDivisions = 4) => {
  const scale: number[] = [];
  for (let i = 0; i <= numberDivisions; i++) {
    scale.push((i * maxContentViewed) / numberDivisions);
  }

  return scale;
};

const convertToCSV = (array) => {
  const keys = Object.keys(array[0]);
  const header = keys.join(",");
  const rows = array
    .map((member) => keys.map((key) => member[key]).join(","))
    .join("\n");
  return `${header}\n${rows}`;
};

export const getListCSV = (memberList) => {
  const listCSV: ListCSV[] = [];

  if (!!memberList?.length) {
    memberList?.map((member) =>
      listCSV.push({
        id: member.id,
        firstName: member?.user?.firstName,
        lastName: member?.user?.lastName,
        email: member?.email,
        status: member?.status,
        memberSince: member.user?.createdAt
          ? dayjs(member.user?.createdAt).format("D/M/YYYY")
          : "",
      })
    );
  }

  return listCSV;
};

export const downloadCSV = (list, title) => {
  const csv = convertToCSV(list);

  const blob = new Blob([csv.toString()], {
    type: "text/csv;charset=utf-8;",
  });
  const link = document.createElement("a");

  link.href = URL.createObjectURL(blob);

  link.target = "_blank";

  link.download = `${title}.csv`;

  link.click();
};

const removeCharactersObject = (list) => {
  const filterList = list.map((ob) => {
    const obj = {};
    Object.keys(ob).forEach((key) => {
      const removeKey = key.replace(/^"|"$/g, "");
      obj[removeKey] = ob[key];
    });
    return obj;
  });
  return filterList;
};

export const transfCSV = (text) => {
  const rows = text.split("\n");
  const headers = rows[0].split(",").map((header) => header.trim());
  const data: any = rows.slice(1).map((row) => {
    const values = row
      .split(",")
      .map((value) => value.trim().replace(/^"|"$/g, ""));
    const obj = {};
    headers.forEach((header, index) => {
      obj[header] = values[index];
    });
    return obj;
  });
  const transfData = removeCharactersObject(data);
  return transfData;
};

export const isTodayInWeek = (today, week) => {
  const [start, end] = week.split(" - ").map((date) => dayjs(date, "MM/DD/YY"));
  return today.isAfter(start) && today.isBefore(end);
};

export const getWeekPosition = (startOf, endOf, week) => {
  const [start, end] = week.split(" - ").map((date) => dayjs(date, "MM/DD/YY"));
  return start.isSame(startOf, "day") && end.isSame(endOf, "day");
};

export const showByPercent = (part, total) => {
  const percentage = (part / total) * 100;

  return percentage > 30;
};

export const getMinuteLabel = (count = 0) => {
  return count > 1 ? " minutes" : " minute";
};

export interface MembershipTierText {
  upTo: number | null;
  text?: string;
  flatAmount?: number | null;
  unitAmount?: number | null;
  flatAmountDecimal?: string | null;
  unitAmountDecimal?: string | null;
}

export const generateMembershipTierText = (
  tiers: Tier[],
  memberLimit: number
): MembershipTierText[] | null => {
  let start = 1;
  if (!tiers?.length) return null;
  return tiers?.map((item): MembershipTierText => {
    const end = item.upTo || memberLimit;
    const text = `${start}-${end}`;
    start = end + 1;
    return { ...item, text, upTo: item.upTo || null };
  });
};
