/* eslint-disable camelcase */
import { useEffect } from "preact/hooks";

import { toSnake, keysToSnake } from "helpers/humps";
import User from "util/userUtil";
import { getCookie } from "util/cookie";
import { segmentLibraryUrl } from "config";

export type SegmentEventType =
  | "CTA Tapped"
  | "Page Viewed"
  | "Org Sign Up Viewed"
  | "Sign Up Viewed"
  | "Signed Up"
  | "Account Created"
  | "Signed In"
  | "Signed Out"
  | "Form Submitted"
  | "Subscribed"
  | "Form Submitted Error"
  | "Content Searched"
  | "Content Favorited"
  | "Content Favorited Removed"
  | "Content Started"
  | "Content Play Button Selected"
  | "Content Screen Expand Selected"
  | "Content Fast Forwarded"
  | "Content Stopped"
  | "Content Rewinded"
  | "Content Pause Button Selected"
  | "Content Completed"
  | "Content Casted"
  | "Content Casted Cancelled"
  | "Order Completed"
  | "Modal Shown"
  | "Modal Closed"
  | "Fitness Assessment Content Selected"
  | "Sign Up Started"
  | "Email Entered"
  | "Content Shared"
  | "Path Joined"
  | "Path Completed"
  | "Open Path"
  | "Path Week Completed"
  | "Activity Shared"
  | "Post Path Share Selected"
  | "Path Feedback Sent"
  | "Post Path Modal Viewed"
  | "Path Left"
  | "Path Restarted"
  | "Post Routine Modal Viewed"
  | "Routine Rated"
  | "Routine Feedback Sent"
  | "Post Routine Share Selected"
  | "Fitness Assessment Started"
  | "Fitness Assessment Question"
  | "Quiz - Skip"
  | "Fitness Assessment Succeeded"
  | "Mobility Journey Let’s get moving"
  | "Mobility Journey Start your journey"
  | "Mobility Journey Tailor Your Content"
  | "Mobility Journey Completed"
  | "Pose Detail Selected"
  | "Content Skipped"
  | "Discover Selected"
  | "Hub Selected"
  | "Featured Selected"
  | "Mobility Journey Task Selected"
  | "Kameleoon identifier"
  | "Journey Option Selected"
  | "Program Created"
  | "Program Week Completed"
  | "Program Completed"
  | "Program Ended"
  | "Program Week Skipped"
  | "Program Share Selected"
  | "Viewed A/B Test"
  | "Path Shared"
  | "Program Recap Started"
  | "Program Recap Completed"
  | "Post-Program Share Selected"
  | "Program Recap - Create New Program"
  | "Program Recap - Watch Again";

export const useAnalytics = () => {
  const visitorCode = getCookie("kameleoonVisitorCode");

  const user = User.isLoggedIn() && User.hasUserData() && User.userData();
  let analytics;

  useEffect(() => {
    if (typeof window === "undefined" || process.env.NODE_ENV === "test")
      return;

    // Check if analytics is already initialized to prevent multiple initializations
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (
      (window as any).analytics &&
      ((window as any).analytics.initialize ||
        (window as any).analytics.invoked)
    ) {
      // Analytics already initialized, just use the existing instance
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      analytics = (window as any).analytics;
      return;
    }

    // Implementation of the new segment snippet
    const i = "analytics";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics = (window as any)[i] = (window as any)[i] || [];

    if (!analytics.initialize) {
      if (analytics.invoked) {
        window.console &&
          console.error &&
          console.error("Segment snippet included twice.");
      } else {
        analytics.invoked = true;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "screen",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
          "register",
        ];

        analytics.factory = function (e) {
          return function (...args) {
            if ((window as any)[i].initialized)
              return (window as any)[i][e](...(args as any));
            const n = [...args];
            if (
              ["track", "screen", "alias", "group", "page", "identify"].indexOf(
                e
              ) > -1
            ) {
              const c = document.querySelector("link[rel='canonical']");
              n.push({
                __t: "bpc",
                c: (c && c.getAttribute("href")) || undefined,
                p: location.pathname,
                u: location.href,
                s: location.search,
                t: document.title,
                r: document.referrer,
              });
            }
            n.unshift(e);
            analytics.push(n);
            return analytics;
          };
        };

        for (let n = 0; n < analytics.methods.length; n++) {
          const key = analytics.methods[n];
          analytics[key] = analytics.factory(key);
        }

        analytics.load = function (key, n) {
          const t = document.createElement("script");
          t.type = "text/javascript";
          t.async = true;
          t.setAttribute("data-global-segment-analytics-key", i);

          // Use different URLs based on environment
          t.src =
            segmentLibraryUrl ||
            `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;

          const r = document.getElementsByTagName("script")[0];
          r.parentNode?.insertBefore(t, r);
          analytics._loadOptions = n;
        };

        analytics._writeKey = process.env.SEGMENT_API_KEY || "";
        analytics.SNIPPET_VERSION = "5.2.0";

        analytics.load(process.env.SEGMENT_API_KEY);
        analytics.page();
      }
    }
  }, []);

  const track = (event: SegmentEventType, properties: any = {}) => {
    // Common to all tracks
    properties.platform = "Web";
    if (event == "CTA Tapped" && !properties.url) {
      properties.url = window.location.href;
    }

    if (visitorCode) {
      properties["k_visitorCode"] = visitorCode;
    }
    const userTrack =
      User.isLoggedIn() && User.hasUserData() && User.userData();
    if (userTrack) {
      const { id, firstName, lastName, email, subscription } = userTrack;

      const traits = {
        id,
        firstName,
        lastName,
        trialStartDate: subscription?.trialStartedAt,
        trialEndDate: subscription?.trialEndsAt,
        subscriptionStartDate: subscription?.currentPeriodStartedAt,
        subscriptionPlanName: subscription?.plan?.name,
      };

      if (event == "Subscribed" || event === "Signed Up") {
        traits["email"] = email;
      }

      if (visitorCode) {
        traits["k_visitorCode"] = visitorCode;
      }

      window.analytics?.track(
        event,
        keysToSnake({
          ...properties,
        }),
        keysToSnake({ traits })
      );
    } else {
      window.analytics?.track(event, keysToSnake(properties));
    }
  };

  const page = (event: string, properties = {}) => {
    if (user) properties["userId"] = user.id;
    if (visitorCode) {
      properties["k_visitorCode"] = visitorCode;
    }
    analytics?.page(toSnake(event), properties);
  };

  const identify = () => {
    const userIdentify =
      User.isLoggedIn() && User.hasUserData() && User.userData();

    if (userIdentify) {
      const { id, firstName, lastName, email, subscription, source } =
        userIdentify;
      const traits = {
        id,
        firstName,
        lastName,
        email,
        source,
        trialStartDate: subscription?.trialStartedAt,
        trialEndDate: subscription?.trialEndsAt,
        subscriptionStartDate: subscription?.currentPeriodStartedAt,
        subscriptionPlanName: subscription?.plan?.name,
      };
      if (visitorCode) {
        traits["k_visitorCode"] = visitorCode;
      }
      window.analytics?.identify(userIdentify.id, traits);
    }
  };

  return { track, page, identify };
};
