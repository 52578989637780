import createStore, { Store } from "unistore";
import devtools from "unistore/devtools";
import { LitePath, Path, UserPath } from "models/path";
import { Schedule, ScheduledWorkout } from "models/schedule";
import { SearchResults } from "models/searchResults";
import { User, ShopifyProduct } from "models/user";
import { Video } from "models/video";
import {
  Athlete,
  Duration,
  Equipment,
  Exercise,
  Pose,
  TargetArea,
  Sport,
  PlaybackToken,
} from "models/videoDetail";
import { SearchVideoOptions } from "models/search";
import { Favorite } from "models/favorite";
import {
  SubscriptionGroup,
  SubscriptionGroupAnalytics,
  SubscriptionGroupInvite,
} from "models/subscriptionGroup";
import { AppProps } from "models/appProps";
import { ReqProps } from "models/reqProps";
import { RefPartner } from "models/refPartner";
import { WelcomeModalType } from "models/welcomeModal";
import { Survey } from "models/survey";
import { Plan, PlanInfo, Subscription } from "models/subscription";
import { ContentBySurvey, HubResponse } from "models/hub";
import { Program, ProgramTag } from "models/program";

export interface AppState {
  // Current schedule either driving schedule page or for displaying WOD info on dashboard
  schedule?: Schedule | null;
  // Current Video either currently displayed in WOD or in current playback screen
  currentVideo?: Video | null;
  // Token needed to playback the current video in player
  currentVideoToken?: PlaybackToken | PlaybackToken[] | null;
  // Token needed to playback the current pose in player
  currentPoseToken?: PlaybackToken | PlaybackToken[] | null;
  // Current workout from the weekly schedule in dashboard
  currentWorkout?: ScheduledWorkout | null;
  // Current video from the VOD in dashboard
  vod?: Video | null;
  // List of videos from /search api filtered by search params
  searchResults?: SearchResults | null;
  // Search filters and options
  searchOpts?: SearchVideoOptions;
  // List of Paths avail for users to take
  featuredPaths?: Path[] | null;
  welcomePath?: Path | null;
  // List of Paths avail for users to take
  paths?: Path[] | null;
  litePaths?: LitePath[] | null;
  currentPath?: Path | null;
  currentProgram?: Program | null;
  // List of User Paths started or completed
  userPaths?: UserPath[] | null;
  // List of User Programs started or completed
  userPrograms?: Program[] | null;
  programTags?: ProgramTag[] | null;
  // Currently logged in/authenticated user/subscriber
  user?: User | null;
  // List of Current User's Marked Favorite Videos
  favorites?: Favorite[] | null;
  // List of Current User's Latest Activity
  latestActivity?: Video[] | null;
  // List of Video Details' Athletes
  athletes?: Athlete[] | null;
  // List of Video Details' Durations
  durations?: Duration[] | null;
  // List of Video Details' Equipment
  equipment?: Equipment[] | null;
  // List of Video Details' Exercises
  exercises?: Exercise[] | null;
  // List of Video Details' Poses
  poses?: Pose[] | null;
  // List of Video Details' Target Areas
  sports?: Sport[] | null;
  // List of Video Details sports
  targetAreas?: TargetArea[] | null;
  // User billing plans
  plans?: Plan[] | null;
  selectedPlanInfo?: PlanInfo | null;
  // Current url that is available under the same api via widow or from worker in SSR
  hub?: HubResponse | null;
  discover?: HubResponse | null;
  currentUrl?: string | null;
  // Shopify Routine Equipment
  routineEquipment?: ShopifyProduct[] | null;
  // AppProps
  appProps?: AppProps | null;
  // Request Props passed from worker request
  reqProps?: ReqProps | null;
  refPartner: RefPartner | null;
  subscriptionGroupInvites?: SubscriptionGroupInvite[] | null;
  subscriptionGroup: SubscriptionGroup | null;
  subscriptionGroupAdmin: Subscription | null;
  subscriptionGroupAnalytics: SubscriptionGroupAnalytics | null;
  searchOptsMembers: any;
  searchResultsMembers: any;
  lastFullVideo?: string;
  nativeNotificationAccepted?: boolean | null;
  welcomeModal?: WelcomeModalType | null;
  survey: Survey | null;
  contentPreferences?: Survey | null;
  playingVideo?: boolean | null;
  contentBySurvey?: ContentBySurvey | null;
  searchPathResults?: any | null;
  searchHubResults?: any | null;
}

export const initialStore: AppState = {
  schedule: null,
  currentVideo: null,
  currentVideoToken: null,
  currentPoseToken: null,
  currentWorkout: null,
  vod: null,
  searchResults: null,
  searchOpts: {},
  featuredPaths: null,
  paths: null,
  litePaths: null,
  currentPath: null,
  welcomePath: null,
  userPaths: null,
  userPrograms: null,
  user: null,
  favorites: null,
  athletes: null,
  durations: null,
  equipment: null,
  exercises: null,
  poses: null,
  sports: null,
  targetAreas: null,
  plans: null,
  selectedPlanInfo: null,
  currentUrl: null,
  appProps: {},
  reqProps: {},
  routineEquipment: null,
  subscriptionGroup: null,
  subscriptionGroupAdmin: null,
  subscriptionGroupAnalytics: null,
  subscriptionGroupInvites: null,
  searchOptsMembers: null,
  searchResultsMembers: null,
  hub: null,
  discover: null,
  refPartner: null,
  nativeNotificationAccepted: null,
  welcomeModal: null,
  survey: null,
  contentPreferences: null,
  playingVideo: null,
  contentBySurvey: null,
};

const store = (initState: AppState = initialStore): Store<AppState> =>
  ["production", "test"].includes(process.env.NODE_ENV!)
    ? createStore(initState)
    : devtools(createStore(initState));

export default store;
