/** ROUTES */

const staticBaseURL = "https://static.romwod.com/pwa/";
export const muxPlaybackDomain = "content.pliability.com";
export const imagesURL = `${staticBaseURL}images/`;
export const assetsURL = `${staticBaseURL}assets/`;
export const videoURL = `${process.env.VIDEO_HOST || ""}`;
export const muxDataKey = `${process.env.MUX_DATA_KEY || ""}`;
export const castAppId = `${process.env.CAST_APP_ID || ""}`;
export const prosperStackClientId = `${
  process.env.PROSPER_STACK_CLIENT_ID || ""
}`;
export const pubPath = `${process.env.PUBLIC_PATH || ""}`;
export const pubPathNoTrailingSlash = pubPath
  ? pubPath.substring(0, pubPath.length - 1)
  : "";
export const gaUaId = `${process.env.GA_UA_ID || ""}`;
export const gAuthClientId = `${process.env.GOOGLE_AUTH_CLIENT_ID || ""}`;
export const brazeAppId = `${process.env.BRAZE_APP_ID || ""}`;
export const welcomePathId = `${process.env.WELCOME_PATH_ID || "0"}`;
export const segmentLibraryUrl = `${process.env.SEGMENT_URL || ""}`;

/** PAGES */
export const itemsPerPage =
  typeof window !== "undefined"
    ? window.document.documentElement.clientHeight > 920
      ? 20
      : 12
    : 12;

/** Intercom */
export const intercomtTicketContact = `${
  process.env.INTERCOM_TICKET_CONTACT || ""
}`;
