import { FunctionalComponent, h } from "preact";
import { useEffect } from "preact/hooks";
import { StoreProvider } from "@preact-hooks/unistore";
import { RouterOnChangeArgs } from "preact-router";

import { globals } from "./AppStyle";
import { useAnalytics } from "hooks/analyticsHook";
import User from "util/userUtil";
import ExternalServices from "components/layout/ExternalServices";
import ExtendedRoute from "components/layout/ExtendedRoute";
import AppPropsLoader from "components/layout/AppPropsLoader";
import NonceLoader from "components/layout/NonceLoader";
import Redirect from "components/shared/Redirect";

import DashboardPage from "routes/DashboardPage";
import SearchPage from "routes/SearchPage";
import ProfilePage from "routes/ProfilePage";
import NotFoundPage from "routes/NotFoundPage";
import SingleRoutinePage from "routes/SingleRoutinePage";
import SettingsPage from "routes/SettingsPage";
import SinglePathPage from "routes/SinglePathPage";
import SigninPage from "routes/SigninPage";
import SignupPage from "routes/SignupPage";
import OnboardingPage from "routes/OnboardingPage";
import ForgotPasswordPage from "routes/ForgotPasswordPage";
import AcceptOrgInvitePage from "routes/AcceptOrgInvitePage";
import SubscribePage from "routes/SubscribePage";
import { initialStore, AppState } from "store/store";
import store from "store/store";
import { useCurrentUrl } from "hooks/currentUrlHook";
import { toCamel } from "helpers/humps";
import Router from "components/shared/router";
import { AccountType } from "models/subscription";
import { SignUpSteps } from "models/signupSteps";
import SingleProgramPage from "routes/SingleProgramPage";
import DiscoverPage from "routes/DiscoverPage";
import OrgSignupPage from "routes/OrgSignupPage";
import TeamPage from "routes/TeamPage";
import MagicSignInPage from "routes/MagicSignInPage";

if ((module as NodeModule).hot) {
  // tslint:disable-next-line:no-var-require
  require("preact/debug");
}

interface AppProps {
  ssrState: Partial<AppState>;
  url: string;
}

declare global {
  interface Window {
    analytics: any;
    Intercom: any;
  }
}

const App: FunctionalComponent<AppProps> = ({ ssrState, url }: AppProps) => {
  const { page } = useAnalytics();

  const handleRoute = (e: RouterOnChangeArgs) => {
    const currentUrl: string = e.url;
    const { setCurrentUrlAction } = useCurrentUrl();
    setCurrentUrlAction(
      typeof window !== "undefined" ? window.location.href : currentUrl
    );
    page(currentUrl, {});
    if (typeof window !== "undefined") window.scrollTo(0, 0);
  };

  let initState: AppState;
  if (ssrState) initState = { ...initialStore, ...ssrState };
  else if (typeof window != "undefined") {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initState = { ...initialStore, ...(window as any)?.__PRELOADED_STATE__ };
  } else initState = initialStore;

  const utmUrl =
    typeof window !== "undefined" ? new URL(window.location.href) : null;
  const utmOpts: {
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
  } = {
    utmSource: "",
    utmCampaign: "",
    utmMedium: "",
  };

  if (utmUrl) {
    for (const [key, value] of utmUrl.searchParams) {
      utmOpts[toCamel(key)] = value;
    }
  }

  const { utmSource, utmMedium, utmCampaign } = utmOpts;

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      (utmSource || utmMedium || utmCampaign)
    ) {
      User.setUtm(utmCampaign, utmSource, utmMedium, window.location.href);
    }
  }, [utmSource, utmMedium, utmCampaign]);

  return (
    <StoreProvider value={store(initState)}>
      <div id="app" className={globals}>
        <ExternalServices />
        <AppPropsLoader />
        <NonceLoader />
        <Router onChange={handleRoute} url={url}>
          <ExtendedRoute
            isPrivate
            path="/"
            component={DashboardPage}
            showMobile
          />
          <ExtendedRoute
            isPrivate
            path="/wod/:scheduleSlug"
            component={DashboardPage}
          />
          <ExtendedRoute
            isPrivate
            path="/mobility-journey"
            component={DashboardPage}
          />
          <Redirect path="/wod" to="/" />
          <ExtendedRoute isPrivate path="/activity" component={ProfilePage} />
          <ExtendedRoute isPrivate path="/favorites" component={ProfilePage} />
          <ExtendedRoute isPrivate path="/recents" component={ProfilePage} />
          <ExtendedRoute path="/settings/:option?" component={SettingsPage} />
          <ExtendedRoute isPrivate path="/search" component={SearchPage} />
          <ExtendedRoute
            isPrivate
            path="/discover/:hubSlug?"
            component={DiscoverPage}
          />
          <ExtendedRoute
            isPrivate
            path="/team/:slug?"
            component={TeamPage}
            org
            showMobile
          />
          <Redirect path="/routines/all-routines" to="/routines" />
          <ExtendedRoute
            isPrivate
            path="/routines/:category?/:slug?"
            component={DiscoverPage}
          />
          <ExtendedRoute
            path="/routine/:slug"
            component={SingleRoutinePage}
            showMobile
          />
          <ExtendedRoute isPrivate path="/paths" component={DiscoverPage} />
          <ExtendedRoute
            path="/path/:slug"
            component={SinglePathPage}
            showMobile
          />
          <ExtendedRoute
            isPrivate
            path="/programs/:programId"
            component={SingleProgramPage}
          />
          <ExtendedRoute
            isPrivate
            path="/target-areas/:slug?"
            component={DiscoverPage}
            category="target-areas"
          />
          <ExtendedRoute
            isPrivate
            path="/movements/:slug?"
            component={DiscoverPage}
            category="movements"
          />
          <ExtendedRoute
            isPrivate
            path="/sports/:slug?"
            component={DiscoverPage}
          />
          <ExtendedRoute path="/signin/:slug?" component={SigninPage} />
          <ExtendedRoute path="/signin/forgot" component={ForgotPasswordPage} />
          <ExtendedRoute
            path="/signin/passwordless"
            component={MagicSignInPage}
          />
          <ExtendedRoute
            path="/users/password/edit"
            component={ForgotPasswordPage}
          />
          <ExtendedRoute
            path="/group-invite/:inviteCode"
            component={AcceptOrgInvitePage}
          />
          <Redirect path="/reactivate" to="/signin/reactivate" carryQuery />
          <Redirect path="/activate" to="/signin/activate" carryQuery />
          <ExtendedRoute
            path="/signup/ath-payment"
            component={OnboardingPage}
            compProps={{ pageType: AccountType.Athlete }}
          />
          <ExtendedRoute
            path="/signup/org-payment"
            component={OnboardingPage}
            compProps={{ pageType: AccountType.Organization }}
          />
          <ExtendedRoute
            path="/org-signup/:slug?"
            component={OrgSignupPage}
            compProps={{ step: SignUpSteps.SignupOrg }}
          />

          <Redirect path="/signup-org/:slug?" to="/org-signup" />
          <Redirect path="/ath-signup" to="/signup" />
          <ExtendedRoute path="/signup/:step?" component={SignupPage} />
          <ExtendedRoute
            path="/join"
            component={SignupPage}
            compProps={{ step: SignUpSteps.SignupIos }}
          />
          <Redirect
            path="/signup-step/password-ath"
            to="/signup/password-ath"
          />
          <Redirect
            path="/signup-step/password-org"
            to="/signup/password-org"
          />
          <Redirect path="/signup-step/profile-ath" to="/signup/password-ath" />
          <Redirect path="/signup-step/profile-org" to="/signup/password-ath" />
          <ExtendedRoute path="/subscribed" component={SubscribePage} />
          <NotFoundPage default />
        </Router>
      </div>
    </StoreProvider>
  );
};

export default App;
