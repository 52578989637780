import { API_BASE, APIResponse, callApi, ApiTokenResponse } from "./api";
import { Video } from "models/video";
import { searchOptsToQueryString } from "util/SearchUtil";
import { SearchVideoOptions } from "models/search";

// Cache of ongoing requests to prevent duplicate concurrent requests
const ongoingRequests: Map<string, Promise<APIResponse<Video[]>>> = new Map();

export async function searchVideos(
  opts?: SearchVideoOptions
): Promise<APIResponse<Video[]>> {
  try {
    // Create a unique key based on the search parameters
    const queryString = searchOptsToQueryString(opts);
    const requestKey = `${API_BASE}/videos/search?${queryString}`;

    // Check if there's already an identical request in progress
    if (ongoingRequests.has(requestKey)) {
      // Return the existing promise to avoid duplicate requests
      return ongoingRequests.get(requestKey)!;
    }

    // Create a new request promise
    const requestPromise = (async () => {
      try {
        const resp = await callApi(requestKey);
        return resp;
      } catch (e: any) {
        return e;
      } finally {
        // Remove from ongoing requests when complete
        ongoingRequests.delete(requestKey);
      }
    })();

    // Store the promise in our cache
    ongoingRequests.set(requestKey, requestPromise);

    // Return the promise
    return requestPromise;
  } catch (e: any) {
    return e;
  }
}

export async function videoBySlug(slug: string): Promise<APIResponse<Video>> {
  try {
    const resp = await callApi(`${API_BASE}/videos/by-slug/${slug}`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function videoByShortcode(
  shortCode: string
): Promise<APIResponse<Video>> {
  try {
    const resp = await callApi(`${API_BASE}/videos/info/${shortCode}`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function videoToken(
  playbackId: string | string[]
): Promise<ApiTokenResponse | ApiTokenResponse[]> {
  try {
    const opts = { playbackId };
    const resp = await callApi(`${API_BASE}/videos/token`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e: any) {
    return e;
  }
}
